import { Outlet, useLocation, useNavigation } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import { ChevronRight } from "lucide-react";
import React from "react";
import { showLatestUpdateBarAtom, useAtom } from "~/atoms";
import { Sidenav } from "~/components/sidenav";
import { TopBar } from "~/components/topbar";
import { useIsCollapse } from "~/hooks/use-is-collapse";
import { cn } from "~/util/css";

export default function AppLayout() {
  const [isCollapse] = useIsCollapse();
  const scrollingRef = React.useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const [showLatestUpdateBar] = useAtom(showLatestUpdateBarAtom);

  React.useEffect(() => {
    if (scrollingRef.current) {
      scrollingRef.current.scroll({ top: 0 });
    }
  }, [pathname]);

  const navigation = useNavigation();
  return (
    <div className="bg-[radial-gradient(113%_91%_at_5%_9%,#93B4FF_23%,#FF000000_99%),radial-gradient(142%_91%_at_111%_84%,#A644FF_0%,#A644FF_100%)] h-screen overflow-hidden">
      <LatestUpdateBar />
      <div className="flex flex-col lg:flex-row h-full max-w-screen-2xl mx-auto">
        <Sidenav
          className={cn(
            "w-[252px] hidden lg:flex transition-width duration-150",
            {
              "w-[126px]": isCollapse,
            },
          )}
        />
        <div
          className={cn(
            "lg:w-[calc(100%-252px)] h-full transition-width duration-150 relative",
            {
              "lg:w-[calc(100%-126px)]": isCollapse,
            },
          )}
        >
          <CollapseButton />
          <TopBar />
          <div
            className={cn(
              "lg:rounded-tl-xl 2xl:rounded-t-xl overflow-hidden h-[calc(100%-80px)] relative",
              {
                "h-[calc(100%-124px)]": showLatestUpdateBar,
              },
            )}
          >
            <div className="bg-[#e9ecff] h-full lg:rounded-tl-xl 2xl:rounded-t-xl relative overflow-hidden">
              <div
                className="overflow-y-auto overflow-x-hidden w-full h-full"
                ref={scrollingRef}
              >
                {navigation.state !== "idle" && <LoadingBar />}
                <Outlet context={{ scrollingRef }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CollapseButton() {
  const [isCollapse, setIsCollapse] = useIsCollapse();
  return (
    <div className="absolute top-[70px] left-[-7px] z-10 hidden lg:block">
      <button
        type="button"
        className="w-[23px] h-[23px] rounded-full border border-black flex items-center justify-center bg-white shadow-[0px_1px_0px_0px_#000] bg-[linear-gradient(180deg,#DCAFFF_0%,#BFDDFF_100%)]"
        onClick={() => setIsCollapse(!isCollapse)}
      >
        <img
          src="/icons/expand_content.svg"
          alt="expand"
          className="w-[10px] h-[10px]"
        />
      </button>
    </div>
  );
}

export function LoadingBar() {
  return (
    <>
      <div
        className="absolute z-20 shadow-lg w-full h-1 bg-gradient-to-r from-pink-400 via-transparent to-teal-300 animate-[loading_0.5s_linear_infinite]"
        style={{
          WebkitBackgroundSize: "50% 100%",
        }}
      />
      <div className="fixed w-full max-w-screen-xl h-screen rounded-xl bg-black bg-opacity-10 z-10 overflow-hidden" />
    </>
  );
}

function LatestUpdateBar() {
  const [_, setShow] = useAtom(showLatestUpdateBarAtom);

  const getLatest = async (): Promise<{
    title: string;
    link: string;
  } | null> => {
    try {
      const res = await fetch("/api/latest");
      const data = await res.json();
      return data.latest;
    } catch (error) {
      console.error("Failed to get latest update", error);
    }
    return null;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["latest"],
    queryFn: () => getLatest(),
    enabled: typeof window !== "undefined",
  });

  React.useEffect(() => {
    if (data?.title) {
      setShow(true);
    }
  }, [data]);

  if (isLoading || !data || !data?.title) {
    return null;
  }

  return (
    <div className="w-full h-11 flex gap-6 items-center justify-center bg-[#4B279D]">
      <div className="flex items-center h-full gap-2">
        <div className="border-[#00FFC2] border rounded-md text-xs px-2 py-1 text-white">
          NEW
        </div>
        <p className="text-white text-sm hover:underline whitespace-nowrap font-medium">
          {data?.title}
        </p>
      </div>
      {data?.link && (
        <a
          href={data.link}
          target="_blank"
          rel="noreferrer"
          className="text-[#AA9CFF] text-sm flex items-center gap-1 font-medium"
        >
          Learn more <ChevronRight size={16} />
        </a>
      )}
    </div>
  );
}
